import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import CircularLoad from '../../Components/Load';
import conexao from '../../Services/api';
import Logo from '../../Assets/Images/LogoCP.svg';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import './css/style.css';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { campovazio } from '../../Components/ReduxTeste/ValidateForm'
import { telefones, validateEmail } from '../../Components/ReduxTeste/Validate'
import FormHelperText from '@material-ui/core/FormHelperText';
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginBottom: '1rem',
      width: '100%',
    },
    width: '80%',
    minHeight: '100%'
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  checkedAceito: {
    marginTop: '.5rem'
  }
}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
}, ptBR);


const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#27b8d2',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#27b8d2',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 5,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#27b8d2',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#27b8d2',
    zIndex: 1,
    fontSize: 22,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};


const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

function getSteps() {
  return ['Dados da clínica', 'Fim do cadastro'];
}

export default function ClinicSignup() {
  const classes = useStyles();
  const History = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [load, setLoad] = useState(false);
  const [openalert, setOpenalert] = useState({ open: false })


  function Alertshow(msg, type) {
    setOpenalert({ open: true, msg: msg, type: type })
    setTimeout(() => {
      setOpenalert({ open: false })
    }, 3000);
  }


  const initialvalidate = {
    nome: { name: 'nome', funcao: campovazio, error: false, value: '' },
    email: { name: 'email', funcao: validateEmail, error: false, value: '' },
    telefone: { name: 'telefone', funcao: campovazio, error: false, value: '' },
    senha: { name: 'senha', funcao: campovazio, error: false, value: '' },
  }

  const [dados, setDados] = useState({
    "name": "",
    "email": "",
    "cnpj": "",
    "phone": "",
    "cellphone": "",
    "is_whatsapp": true,
    "birthday": "",
    "street": "",
    "neighborhood": "",
    "number": '',
    "city": "",
    "state": "",
    "complement": "",
    "zip_code": "",
    "avatar": "",
  })

  const [validatex, setValidatex] = useState(initialvalidate)

  function onchangeValidateTeste(e) {
    const { name, value } = e.target
    const obj = validatex[name]
    const er = validatex[name].funcao(value)
    let att = { ...obj, error: er, value: value }
    setValidatex({ ...validatex, [name]: att })
  }

  const handleNext = () => {

    if (activeStep === 0) {
      if (validatex.nome.value === '') {
        validatex.nome.error = true
        Alertshow('dados inválidos!', 'error')
      }
      if (validatex.email.value === '') {
        validatex.email.error = true
        Alertshow('dados inválidos!', 'error')
      }
      if (validatex.telefone.value === '') {
        validatex.telefone.error = true
        Alertshow('dados inválidos!', 'error')
      }
      setValidatex({ ...validatex })

      if (validatex.nome.error === false && validatex.email.error === false && validatex.telefone.error === false) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }

    if (activeStep === 1) {
     
      if (validatex.senha.value === '' || validatex.senha.value.length < 6) {
        validatex.senha.error = true
        Alertshow('dados inválidos!', 'error')
      }
      if (state.checkedA === false) {
        Alertshow('Aceite os termos de uso para prosseguir', 'warning')

      }
      setValidatex({ ...validatex })

      if (validatex.senha.error === false && state.checkedA === true) {

        localStorage.removeItem('token')
        localStorage.removeItem('userId')
        localStorage.removeItem('clinicId')
        setLoad(true)

        let tel = validatex.telefone.value.replace(/\D/g, '')

        const obj = {
          name: validatex.nome.value,
          phone: tel,
          email: validatex.email.value,
          password: validatex.senha.value,
        }
        
        conexao.post('/clinic/create', obj).then((res) => {

          setLoad(false)

          localStorage.setItem('token', res.data.data.login.token)
          localStorage.setItem('userId', res.data.data.login.user_id)
          localStorage.setItem('profileId', res.data.data.login.profile_id)
          localStorage.setItem('avatar', res.data.data.login.avatar)
          if (res.data.data.login.clinic_id)
            localStorage.setItem('clinicId', res.data.data.login.clinic_id)
          localStorage.setItem('trial', 1)
          localStorage.setItem('loginType', 'CLINIC')

          setLoad(false)

          localStorage.setItem('selectedDoctor', res.data.data.login.doctor_id)
          localStorage.setItem('doctorId', res.data.data.login.doctor_id) //Compatibilidade com código antigo
          localStorage.setItem('selectedUser', res.data.data.login.user_id)
          localStorage.setItem('userIdModalUpdate', res.data.data.login.user_id)
          localStorage.setItem('doctorName', res.data.data.login.name)

          var obj = [];

          var count = 0
          console.log('PERMISSION')
          localStorage.setItem('permission', res.data.data.login.permission)
          if (res.data.data.login.permission) {
            const arr = res.data.data.login.permission.toString().split(',');
              arr.map((item) => {
              localStorage.setItem(item, 'true')
            })
          }

          localStorage.setItem('adminAlert', 'Bem vindo ao Clinic Plus! Seu período de avaliação termina em ' + res.data.data.login.remain_days + ' dias')
          localStorage.setItem('containerClass','containerClinicAlert');

          Alertshow('Cadastro realizado com sucesso!', 'success')

          setTimeout(() => {

              // Se estiver no celular, redireciona para o mobile
              if(isMobile) {
                window.location.href = 'https://mobile.clinicplusapp.com';
              } else {
                window.location.href  =  '/home'
              }

          }, 1500);
        }).catch((error) => {
          
          setLoad(false)
          Alertshow('Esse e-mail já está sendo utilizado!', 'error')
        })
      }
    }
  }



  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };  

  function enterPress(e){

    if(e.keyCode === 13){

      handleNext()

    }
  }

  return (
    <div className="signup-container">
      <ThemeProvider theme={theme}>
        
        <div className="signup-wrapper" onKeyDown={(e)=>{enterPress(e)}} >
          <img className="header-logo" src={Logo} alt="Logo Clinic" />
          
          <h1 className="signup-title">Cadastro de Clínica</h1>

          <form className="signup-form" autoComplete="off" onSubmit={(e)=>{e.preventDefault()}}>
            {openalert.open ?
              <Alert variant="filled" severity={openalert.type}>
                {openalert.msg}
              </Alert>
              : ''}
            <div >
              <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {activeStep === 0 ?
              <div className="envolve-textField" >
                <TextField
                  onChange={(e) => { onchangeValidateTeste(e) }}
                  error={validatex.nome.error}
                  value={validatex.nome.value}
                  helperText={validatex.nome.error ? 'Este Campo precisa ser preenchido' : ''}
                  type="text" name="nome" label="Nome da clínica" variant="outlined" color="primary" required />

                <TextField
                  onChange={(e) => { onchangeValidateTeste(e) }}
                  error={validatex.email.error}
                  value={validatex.email.value}
                  helperText={validatex.email.error ? 'Email inválido' : ''}
                  type="email" name="email" label="E-mail" variant="outlined" color="primary" required />

                <TextField
                  onChange={(e) => { onchangeValidateTeste(e) }}
                  error={validatex.telefone.error}
                  value={telefones(validatex.telefone.value)}
                  helperText={validatex.telefone.error ? 'Este Campo precisa ser preenchido' : ''}
                  type="tel" name="telefone" label="Telefone" variant="outlined" color="primary" required />
              </div>
              :
              activeStep === 1 ?
                  <div className="envolve-textField">

                    <FormControl variant="outlined">
                      <InputLabel error={validatex.senha.error ? true : false} htmlFor="senha">Senha</InputLabel>
                      <OutlinedInput
                        id="senha"
                        name="senha"
                        onChange={(e) => { onchangeValidateTeste(e) }}
                        error={validatex.senha.error ? true : false}
                        type={values.showPassword ? 'text' : 'password'}
                        value={validatex.senha.value}
                        //onChange={handleChange('password')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        labelWidth={50}
                      />
                    </FormControl>
                    <FormHelperText style={{ color: 'red' }} id="component-error-text">{validatex.senha.error ? 'Este Campo precisa ser preenchido' : ''}</FormHelperText>

                    <FormControlLabel
                      className={classes.checkedAceito}
                      control={<Checkbox checked={state.checkedA} color="primary" onChange={handleChangeCheck} name="checkedA" />}
                      label={
                        <React.Fragment>
                          <p className="termosPlan">
                            Eu aceito os 
                            <a href="https://clinicplusapp.com/app/termos_uso/documento.pdf" target="_blank" rel="noreferrer">Termos de uso</a> e 
                            <a href="https://clinicplusapp.com/app/politica_uso/documento.pdf" target="_blank" rel="noreferrer"> Política de Privacidade</a>
                          </p>
                        </React.Fragment>
                      }
                    />
                  </div>
                  : ''}
            <div className="div-btn-cadastro">
              <div>
                <div>
                  <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                    Voltar
                  </Button>
                  {!load ?
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      
                      className={clsx(classes.button, "color-primary")}
                    >
                      {activeStep === 1 ? 'Cadastrar' : 'Próximo'}
                      {activeStep !== 1 ? <ArrowForwardIcon className="margin-left-icon-cadastro" /> : ''}
                    </Button>
                    :
                    <Button
                      variant="contained"
                      color="primary"
                      className={clsx(classes.button, "color-primary")}
                    >
                      <CircularLoad />
                    </Button>
                  }
                </div>
              </div>
            </div>
          </form>
        </div>
      </ThemeProvider>
    </div>
  );
}

