import React, { useEffect, useState } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Login from './Pages/NewLogin';
import TesteRedux from './Components/ReduxTeste/index'
import Drag from './Components/Dragdrop';
import ProntuarioListagemModelosSalvos from './Pages/Prontuarios/ListagemModelosSalvos';
import Topo from './Components/MenuTopo';
import Dashboard from './Pages/Dashboard';
import Agenda from './Pages/Agenda';
import NovoModeloProntuario from './Pages/Prontuarios/NovoModeloProntuario';
import Atendimento from './Pages/Atendimento';
import CadastroPaciente from './Pages/CadastroPaciente';
import ProfessionalSignup from './Pages/NewProfessionalSignup';
import ClinicSignup from './Pages/NewClinicSignup';
import EditarCadastroClinica from './Pages/CadastroClinica/EditarCadastro';
import EditarCadastro from './Pages/CadastroProfissional/EditarCadastro';
import PacientesListagem from './Pages/Pacientes';
import Financeiro from './Pages/Financeiro/Resumo'
import AtendenteListagem from './Pages/Atendentes';
import MedicosListagem from './Pages/Medicos';
import Planos from './Pages/Planos';
import ContratarPlanos from './Pages/Planos/ContatarPlanos';
import Relatorio from './Pages/Relatorios';
import TopoRelatorios from './Pages/Relatorios/Components/TopoRelatorio/TopoRelatorio';
import CabeçalhoRelatorio from './Pages/Relatorios/Components/CabeçalhoRelatorios';
import EscalaDeDor from './Pages/Relatorios/Tipos/EscalaDeDor';
import Craniometria from './Pages/Relatorios/Tipos/Craniometria';
import ApplePayNotification from './Components/ApplePayNotification';


import firstPage from './Pages/FirstPage'

import { isIOS } from 'react-device-detect'
import FisioterapiaEsportiva from './Pages/FisioterapiaEsportiva';
import PdfEsportivo from './Pages/FisioterapiaEsportiva/components/PdfEsportivo'
import { SportsPhysiotherapyProvider } from './ContextAPI/SportsPhysiotherapyContext'
// import Nutrição from './Pages/Relatorios/Tipos/Nutrição';
import CurvaDeCrescimento from './Pages/Relatorios/Tipos/CurvaDeCrescimento';
import DesenvolvimentoMotor from './Pages/Relatorios/Tipos/DesenvolvimentoMotor';
// import NutricionalGestante from './Pages/Relatorios/Tipos/NutricionalGestante';
// import Pediatra from './Pages/Relatorios/Tipos/Pediatra';


export default function Routes() {

    useEffect(() => {
        // Visitantes não logados só poderão acessar essas páginas abaixo...
        const pagesAlowed = ['/', '/login', '/cadastro', '/cadastro-clinica'];
        // console.log(window.location.pathname)
        if (!localStorage.getItem('loginType') && !pagesAlowed.includes(window.location.pathname)) {
            window.location.href = '/login';
        }
    },[])

    const Home = () => {
        if (!localStorage.getItem('loginType') && window.location.pathname != '/') {
            // console.log(window.location)
            window.location.href = '/login';
        }
        
        return (
            <div>
                <Topo />
                {/* <Agenda /> */}
            </div>

        )
    }

    return (
        <Switch>
            <Route path="/" exact component={firstPage} />
            <Route path="/login" exact component={Login} />
            <Route path="/home" component={() =>
                localStorage.getItem('/painel') === 'true' ?
                    <div>
                        <Topo />
                        <Dashboard />
                    </div>
                :Home()
            }>
            </Route>
            <Route path="/cadastro" component={() =>
                <div>
                    <ProfessionalSignup />
                </div>
            }>
            </Route>
            <Route path="/cadastro-clinica" component={() =>
                <div>
                    <ClinicSignup />
                </div>
            }>
            </Route>
            <Route path="/relatorios" component={() =>
                <div>
                    <Topo />
                    <Relatorio />
                </div>
            }>
            </Route>
            <Route path="/agenda" component={() =>
                    <div>
                        <Topo/>
                        <Agenda/>
                    </div>
            }>
            </Route>
            <Route path="/planos" component={() =>
                   localStorage.getItem('/plano') || localStorage.getItem('/planos') ?
                    <div>
                        <Topo />
                        { isIOS ? (
                            <ApplePayNotification />
                        ) : (
                            <Planos />
                        )}
                    </div>
                    :
                    Home()
            }>
            </Route>
            <Route path="/contratar-plano" component={() =>
                localStorage.getItem('/plano') || localStorage.getItem('/planos') ?
                    <div>
                        <Topo />
                        <ContratarPlanos />
                    </div>
                     :
                     Home()
            }>
            </Route>
            <Route path="/editar-cadastro" component={() =>
            localStorage.getItem('/perfil') === 'true' ?
                <div>
                    <Topo />
                    <EditarCadastro />
                </div>
                :Home()
            }>
            </Route>
            <Route path="/editar-cadastro-clinica" component={() =>
            localStorage.getItem('/perfil') === 'true' && localStorage.getItem('profileId') == 2 ?
                <div>
                    <Topo />
                    <EditarCadastroClinica />
                </div>
                :Home()
            }>
            </Route>
            <Route path="/listagem-modelos" component={() =>
            localStorage.getItem('/novo-modelo') === 'true' ?
                <div>
                    <Topo />
                    <ProntuarioListagemModelosSalvos />
                </div>
                :
                Home()
            }>
            </Route>
            <Route path="/novo-modelo" component={() =>
                localStorage.getItem('/novo-modelo') === 'true' ?
                    <div>
                        <Topo />
                        <NovoModeloProntuario />
                    </div>
                    : 
                    Home()
            }>
            </Route>
            {/* <Route path="/atendimento" component={() =>
                <div>
                    <Topo />
                    <Atendimento />
                </div>
            }>
            </Route> */}
            <Route path="/atendimento/:id" render={(props) =>
                <div>
                    <Topo />
                    <Atendimento id={props.match.params.id} />
                </div>
            }>
            </Route>
            {/* <Route path="/cadastro-paciente" component={() =>
                <div>
                    <Topo />
                    <CadastroPaciente />
                </div>
            }>
            </Route> */}
            <Route path="/cadastro-paciente/:id" render={(props) =>
                <div>
                    <Topo />
                    <CadastroPaciente id={props.match.params.id} />
                </div>
            }>
            </Route>

            <Route path="/pacientes" component={() =>
                    <div>
                        <Topo />
                        <PacientesListagem />
                    </div>
            }>

            </Route>
            <Route path="/financeiro" component={() =>
             localStorage.getItem('/financeiro') === 'true' ?
                <div>
                    <Topo />
                    <Financeiro />
                </div>
                  : Home()
            }>
          

            </Route>

            <Route path="/teste" component={TesteRedux} />
            <Route path="/drag" component={Drag} />

            <Route path="/atendentes" component={() =>
                localStorage.getItem('/atendentes') === 'true' ?
                <div>
                    <Topo />
                    <AtendenteListagem />
                </div>
                : Home()
            }>
            </Route>

            <Route path="/profissionais" component={() =>
                localStorage.getItem('/profissionais') === 'true' ?
                <div>
                    <Topo />
                    <MedicosListagem />
                </div>
                : Home()
            }>
            </Route>
            
            <Route path="/escala-de-dor/:date" component={() => 
                <div>
                    <TopoRelatorios/>
                    <CabeçalhoRelatorio/>
                    <EscalaDeDor/>
                </div>
            }>
            </Route>

             <Route path="/craniometria/:date" component={() => 
                <div>
                    <TopoRelatorios/>
                    <CabeçalhoRelatorio/>
                    <Craniometria/>
                </div>
            }>
            </Route>
            
            <Route path="/curva-de-crescimento/:date" component={() => 
                <div>
                    <TopoRelatorios/>
                    <CabeçalhoRelatorio/>
                    <CurvaDeCrescimento/>
                </div>
            }>
            </Route>

            <Route path="/desenvolvimento-motor/:date" component={() => 
                <div>
                    <TopoRelatorios/>
                    <CabeçalhoRelatorio/>
                    <DesenvolvimentoMotor/>
                </div>
            }>
            </Route>

            <Route
                path="/fisioterapia-esportiva"
                component={() => 
                    <>
                        <Topo />
                        <FisioterapiaEsportiva />
                    </>
                }
            >
            </Route>

            <Route
                path="/pdf-esportivo"
                component={() => <PdfEsportivo />}
            >
            </Route>
            
        </Switch>
    )

}


//
